/*////////// Site Structure //////////*/
HTML {
  overflow-x: hidden;
  overflow-y: hidden;
  
  width: 100%;

	font-family: LatoWeb, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Light Green */
  background-color: #DBFFF5;
}

BODY {
  overflow-x: hidden;

  width: 100%;

  /* Yellow */
  background-color: #F9F8C0;
}

#root {
  display: block;
  position: relative;
  left: 100%;

  overflow-x: hidden;
  
  width: 100%;

  -webkit-transition: left 0.75s ease-in-out;
  -moz-transition: left 0.75s ease-in-out;
  -o-transition: left 0.75s ease-in-out;
  transition: left 0.75s ease-in-out;
}
