p.TestWeight_Hairline {
    font-family: LatoWebHairline;
}
p.TestWeight_Hairline_i {
    font-family: LatoWebHairline;
    font-style: italic;
}

p.TestWeight_Thin {
    font-family: LatoWebThin;
}
p.TestWeight_Thin_i {
    font-family: LatoWebThin;
    font-style: italic;
}

p.TestWeight_Light {
    font-family: LatoWebLight;
}
p.TestWeight_Light_i {
    font-family: LatoWebLight;
    font-style: italic;
}

p.TestWeight_Regular {
    font-family: LatoWeb;
}
p.TestWeight_Italic {
    font-family: LatoWeb;
    font-style: italic;
}

p.TestWeight_Medium {
    font-family: LatoWebMedium;
}
p.TestWeight_Medium_i {
    font-family: LatoWebMedium;
    font-style: italic;
}

p.TestWeight_Semibold {
    font-family: LatoWebSemibold;
}
p.TestWeight_Semibold_i {
    font-family: LatoWebSemibold;
    font-style: italic;
}

p.TestWeight_Bold {
    font-family: LatoWebBold;
}
p.TestWeight_Bold_i {
    font-family: LatoWebBold;
    font-style: italic;
}

p.TestWeight_Heavy {
    font-family: LatoWebHeavy;
}
p.TestWeight_Heavy_i {
    font-family: LatoWebHeavy;
    font-style: italic;
}

p.TestWeight_Black {
    font-family: LatoWebBlack;
}
p.TestWeight_Black_i {
    font-family: LatoWebBlack;
    font-style: italic;
}
