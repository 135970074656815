#home-wrapper {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0%;

    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    -webkit-scroll-snap-type: mandatory;
    -webkit-scroll-snap-destination: 0% 0%;
    
    width: 100%;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px solid lime;
    
    /* Transparent Blue */
    background-color: rgba(43, 67, 243, 0.2);

    -webkit-transition: left 0.75s ease-in-out;
    -moz-transition: left 0.75s ease-in-out;
    -o-transition: left 0.75s ease-in-out;
    transition: left 0.75s ease-in-out;
}

#home-wrapper > .section:nth-child(1) {
    scroll-snap-align: start;
    -webkit-scroll-snap-coordinate: 0% 0%;
    
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);

    background-color: rgb(96, 64, 128);
}
#home-wrapper > .section:nth-child(2) {
    scroll-snap-align: center;
    -webkit-scroll-snap-coordinate: 0% 50%;
    
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);

    background-color: rgb(190, 164, 216);
}
#home-wrapper > .section:nth-child(3) {
    scroll-snap-align: center;
    -webkit-scroll-snap-coordinate: 0% 50%;
    
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);

    background-color: rgb(218, 208, 228);
}
#home-wrapper >.section:nth-child(4) {
    scroll-snap-align: end;
    -webkit-scroll-snap-coordinate: 0% 100%;

    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);

    background-color: rgb(190, 164, 216);
}





#detail-wrapper {
    width: 100%;
    min-height: 200vh; /* Fallback for browsers that do not support Custom Properties */
    min-height: calc(var(--vh, 1vh) * 200);
    
    background-color: salmon;
}

#detail-wrapper button {
    display: block;

    width: 100%;
    height: 25px;
}





#side-nav {
    display: block;
    position: fixed;
    
    top: 50%;
    right: calc(0% + 15px);
    transform: translateY(-50%);

    background-color: rgb(5, 17, 49);

    -webkit-transition: right 0.75s ease-in-out;
    -moz-transition: right 0.75s ease-in-out;
    -o-transition: right 0.75s ease-in-out;
    transition: right 0.75s ease-in-out;
}

#side-nav a {
    display: block;

    margin: 4px;

    width: 36px;
    height: 36px;

    font-size: 0.75em;
    text-align: center;

    background-color: rgb(66, 173, 177);
}
#side-nav a.active {
    background-color: deeppink;
}
