/* Setup @font-face fonts */





/*** Lato 2.0 webfont http://www.latofonts.com/lato-free-fonts/ ***/

/* Webfont: Lato-Hairline */
@font-face {
  font-family: 'LatoWebHairline';
  src: url(./fonts/lato_2_web/Lato-Hairline.eot); /* IE9 Compat Modes */
  src: url(./fonts/lato_2_web/Lato-Hairline.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(./fonts/lato_2_web/Lato-Hairline.woff2) format('woff2'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-Hairline.woff) format('woff'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-Hairline.ttf) format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-HairlineItalic */
@font-face {
  font-family: 'LatoWebHairline';
  src: url(./fonts/lato_2_web/Lato-HairlineItalic.eot); /* IE9 Compat Modes */
  src: url(./fonts/lato_2_web/Lato-HairlineItalic.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(./fonts/lato_2_web/Lato-HairlineItalic.woff2) format('woff2'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-HairlineItalic.woff) format('woff'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-HairlineItalic.ttf) format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Thin */
@font-face {
  font-family: 'LatoWebThin';
  src: url(./fonts/lato_2_web/Lato-Thin.eot); /* IE9 Compat Modes */
  src: url(./fonts/lato_2_web/Lato-Thin.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(./fonts/lato_2_web/Lato-Thin.woff2) format('woff2'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-Thin.woff) format('woff'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-Thin.ttf) format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-ThinItalic */
@font-face {
  font-family: 'LatoWebThin';
  src: url(./fonts/lato_2_web/Lato-ThinItalic.eot); /* IE9 Compat Modes */
  src: url(./fonts/lato_2_web/Lato-ThinItalic.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(./fonts/lato_2_web/Lato-ThinItalic.woff2) format('woff2'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-ThinItalic.woff) format('woff'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-ThinItalic.ttf) format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Light */
@font-face {
  font-family: 'LatoWebLight';
  src: url(./fonts/lato_2_web/Lato-Light.eot); /* IE9 Compat Modes */
  src: url(./fonts/lato_2_web/Lato-Light.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(./fonts/lato_2_web/Lato-Light.woff2) format('woff2'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-Light.woff) format('woff'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-Light.ttf) format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-LightItalic */
@font-face {
  font-family: 'LatoWebLight';
  src: url(./fonts/lato_2_web/Lato-LightItalic.eot); /* IE9 Compat Modes */
  src: url(./fonts/lato_2_web/Lato-LightItalic.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(./fonts/lato_2_web/Lato-LightItalic.woff2) format('woff2'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-LightItalic.woff) format('woff'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-LightItalic.ttf) format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Regular */
@font-face {
  font-family: 'LatoWeb';
  src: url(./fonts/lato_2_web/Lato-Regular.eot); /* IE9 Compat Modes */
  src: url(./fonts/lato_2_web/Lato-Regular.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(./fonts/lato_2_web/Lato-Regular.woff2) format('woff2'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-Regular.woff) format('woff'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-Italic */
@font-face {
  font-family: 'LatoWeb';
  src: url(./fonts/lato_2_web/Lato-Italic.eot); /* IE9 Compat Modes */
  src: url(./fonts/lato_2_web/Lato-Italic.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(./fonts/lato_2_web/Lato-Italic.woff2) format('woff2'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-Italic.woff) format('woff'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-Italic.ttf) format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Medium */
@font-face {
  font-family: 'LatoWebMedium';
  src: url(./fonts/lato_2_web/Lato-Medium.eot); /* IE9 Compat Modes */
  src: url(./fonts/lato_2_web/Lato-Medium.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(./fonts/lato_2_web/Lato-Medium.woff2) format('woff2'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-Medium.woff) format('woff'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-Medium.ttf) format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-MediumItalic */
@font-face {
  font-family: 'LatoWebMedium';
  src: url(./fonts/lato_2_web/Lato-MediumItalic.eot); /* IE9 Compat Modes */
  src: url(./fonts/lato_2_web/Lato-MediumItalic.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(./fonts/lato_2_web/Lato-MediumItalic.woff2) format('woff2'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-MediumItalic.woff) format('woff'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-MediumItalic.ttf) format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Semibold */
@font-face {
  font-family: 'LatoWebSemibold';
  src: url(./fonts/lato_2_web/Lato-Semibold.eot); /* IE9 Compat Modes */
  src: url(./fonts/lato_2_web/Lato-Semibold.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(./fonts/lato_2_web/Lato-Semibold.woff2) format('woff2'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-Semibold.woff) format('woff'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-Semibold.ttf) format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-SemiboldItalic */
@font-face {
  font-family: 'LatoWebSemibold';
  src: url(./fonts/lato_2_web/Lato-SemiboldItalic.eot); /* IE9 Compat Modes */
  src: url(./fonts/lato_2_web/Lato-SemiboldItalic.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(./fonts/lato_2_web/Lato-SemiboldItalic.woff2) format('woff2'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-SemiboldItalic.woff) format('woff'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-SemiboldItalic.ttf) format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Bold */
@font-face {
  font-family: 'LatoWebBold';
  src: url(./fonts/lato_2_web/Lato-Bold.eot); /* IE9 Compat Modes */
  src: url(./fonts/lato_2_web/Lato-Bold.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(./fonts/lato_2_web/Lato-Bold.woff2) format('woff2'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-Bold.woff) format('woff'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-BoldItalic */
@font-face {
  font-family: 'LatoWebBold';
  src: url(./fonts/lato_2_web/Lato-BoldItalic.eot); /* IE9 Compat Modes */
  src: url(./fonts/lato_2_web/Lato-BoldItalic.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(./fonts/lato_2_web/Lato-BoldItalic.woff2) format('woff2'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-BoldItalic.woff) format('woff'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-BoldItalic.ttf) format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Heavy */
@font-face {
  font-family: 'LatoWebHeavy';
  src: url(./fonts/lato_2_web/Lato-Heavy.eot); /* IE9 Compat Modes */
  src: url(./fonts/lato_2_web/Lato-Heavy.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(./fonts/lato_2_web/Lato-Heavy.woff2) format('woff2'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-Heavy.woff) format('woff'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-Heavy.ttf) format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-HeavyItalic */
@font-face {
  font-family: 'LatoWebHeavy';
  src: url(./fonts/lato_2_web/Lato-HeavyItalic.eot); /* IE9 Compat Modes */
  src: url(./fonts/lato_2_web/Lato-HeavyItalic.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(./fonts/lato_2_web/Lato-HeavyItalic.woff2) format('woff2'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-HeavyItalic.woff) format('woff'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-HeavyItalic.ttf) format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Black */
@font-face {
  font-family: 'LatoWebBlack';
  src: url(./fonts/lato_2_web/Lato-Black.eot); /* IE9 Compat Modes */
  src: url(./fonts/lato_2_web/Lato-Black.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(./fonts/lato_2_web/Lato-Black.woff2) format('woff2'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-Black.woff) format('woff'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-Black.ttf) format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-BlackItalic */
@font-face {
  font-family: 'LatoWebBlack';
  src: url(./fonts/lato_2_web/Lato-BlackItalic.eot); /* IE9 Compat Modes */
  src: url(./fonts/lato_2_web/Lato-BlackItalic.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(./fonts/lato_2_web/Lato-BlackItalic.woff2) format('woff2'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-BlackItalic.woff) format('woff'), /* Modern Browsers */
       url(./fonts/lato_2_web/Lato-BlackItalic.ttf) format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}