/*////////// Work In Progress Overlay //////////*/
#wipOverlay {
	z-index: 1;
	
	display: block;
	position: fixed;
	bottom: 0;
    
	overflow-x: hidden;
	overflow-y: hidden;
	transition: 0.5s;
	
	width: 100%;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
	
	background-color: rgba(32,32,32,1);
	opacity: 1;
}
.wipOverlayContent {
	display: block;
	
	width: 90%;
	
	position: absolute;
	margin: auto;
	top: 60%;
	left: 50%;
	transform: translate(-50%, -60%);
	
	height: auto;
	
/* 	background-color: blue; */
}
#wipOverlay p {
	text-align: center;
}
.wipNotification {
	background-color: #FBD792;
	
	font-size: 1em;
	text-transform: uppercase;
	
	padding: 16px 20%;

	color: #000000;
		
		
}
.wipTitle {
	font-family: 'Abril Fatface', cursive;
	
	font-size: 2.25em;
	letter-spacing: 0.5px;
	
	color: #FFFFFF;
}
.wipSubTitle {
	font-family: 'Abril Fatface', cursive;
	
	font-size: 1.5em;
	letter-spacing: 1.5px;
	
	color: #E2E2E2;
}
#wipOverlay hr {
	width: 35%;
	border-style: solid;
	border-color: #ffffff;
	border-width: 2px;
	margin: 24px auto;
}
.wipOverlayContent article p {
	font-size: 1.2em;
	font-weight: 400;
	
	color: #9D9D9D;
	line-height: 32px;
	
	width: 100%;
	margin: auto;
}
.wipOverlayContent article a {
	font-weight: 600;
	color: #EEEEEE;
	
	text-decoration: none;
}
.wipOverlayContent article a:hover {
	border-bottom: 2px solid;
	padding-bottom: 0px;
}
.wipClose {
	background-color: transparent;
	text-decoration: none;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 16px;
	width: auto;
	
	font-size: 0.85em;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1px;
	
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: 1px solid;
	padding-bottom: 1px;
	
	color: #FFEAC2;
	
	cursor: pointer;	
}
.wipClose:hover {
	border-bottom: 2px solid;
	padding-bottom: 0px;
}

@media screen and (min-width : 420px) {
	.wipOverlayContent {
/* 		background-color: orange; */
		width: 90%;
		
		top: 55%;
		left: 50%;
		transform: translate(-50%, -55%);
	}
	.wipNotification {
		font-size: 1em;
		
		padding: 16px 20%;
	}
	.wipTitle {
		font-size: 2.5em;
		letter-spacing: 0.5px;
	}
	.wipSubTitle {
		font-size: 1.75em;
		letter-spacing: 1.5px;
	}
	#wipOverlay hr {
		width: 25%;
		
		margin-top: 24px;
		margin-bottom: 24px;
	}
	.wipOverlayContent article p {
		font-size: 1.2em;
		line-height: 32px;
		
		width: 100%;
	}
	.wipClose {
		margin-top: 16px;
		
		font-size: 0.85em;
		letter-spacing: 1px;

		padding-bottom: 1px;
	}
}

@media screen and (min-width : 768px) and (min-height : 768px) {
	.wipOverlayContent {
/* 		background-color: yellow; */
		width: 80%;
		
		top: 55%;
		left: 50%;
		transform: translate(-50%, -55%);
	}
	.wipNotification {
		font-size: 1.2em;
		
		padding-top: 24px;
		padding-bottom: 24px;
	}
	.wipTitle {
		font-size: 3.5em;
		letter-spacing: 1px;
	}
	.wipSubTitle {
		font-size: 2.25em;
		letter-spacing: 3px;
	}
	#wipOverlay hr {
		width: 25%;
		
		margin-top: 48px;
		margin-bottom: 48px;
	}
	.wipOverlayContent article p {
		font-size: 1.45em;
		line-height: 40px;
		
		width: 80%;
	}
	.wipClose {
		margin-top: 24px;
		
		font-size: 1.15em;
		letter-spacing: 1px;

		padding-bottom: 1px;
	}
}

@media screen and (min-width : 1366px) and (min-height : 768px) {
	.wipOverlayContent {
/* 		background-color: red; */
		width: 80%;
		
		top: 55%;
		left: 50%;
		transform: translate(-50%, -55%);
	}
	.wipNotification {
		font-size: 1.2em;
		
		padding-top: 24px;
		padding-bottom: 24px;
	}
	.wipTitle {
		font-size: 4em;
		letter-spacing: 1px;
	}
	.wipSubTitle {
		font-size: 2.5em;
		letter-spacing: 3px;
	}
	#wipOverlay hr {
		width: 20%;
		
		margin-top: 48px;
		margin-bottom: 48px;
	}
	.wipOverlayContent article p {
		font-size: 1.45em;
		line-height: 40px;
		
		width: 65%;
	}
	.wipClose {
		margin-top: 24px;
		
		font-size: 1.15em;
		letter-spacing: 1px;

		padding-bottom: 1px;
	}
}